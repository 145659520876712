// src/App.tsx
import React from 'react';
import './App.css';
import AppRoutes from './router';
import Nav from './components/nav';
import Logo from './components/logo';

import { BrowserRouter as Router } from 'react-router-dom'; // Import the Router component

function App() {
  return (
    <div className="App">
      <Router> {/* Wrap your entire app with Router */}
        <header className="nav">
        <div style={{ paddingTop: "20px", textAlign: "center" }}>
        <Logo />
        <Nav />
        </div>
        </header>
        <main>
          <AppRoutes />
        </main>
        <footer >

          </footer>
      </Router>
    </div>
  );
}

export default App;
