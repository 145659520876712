import React from 'react';

const Policy = () => {
  return (
    <div className='centered-container'>
    <div className='policy'>
      <h1>Privacy Policy for Harambot</h1>
      <p><strong>Effective Date:</strong> 10/10/2023</p>
      <p>
        This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use the Discord chat bot ("Bot") that interacts with Yahoo's Fantasy Sports API ("API"). By using the Bot, you consent to the practices described in this Privacy Policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        <strong>1.1. User-provided Information:</strong> We may collect and store information you provide when using the Bot, including but not limited to your Discord username, Yahoo Fantasy Sports API authentication credentials (if applicable), and any other information you choose to share.
      </p>
      <p>
        <strong>1.2. Automatically Collected Information:</strong> The Bot may collect certain information automatically, such as IP addresses, device information, usage data, and server logs. This information helps us monitor and improve the performance and security of the Bot.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        <strong>2.1. Provide and Maintain the Bot:</strong> We use the information collected to operate, maintain, and improve the Bot's functionality and user experience.
      </p>
      <p>
        <strong>2.2. Communicate with You:</strong> We may use your contact information to send you important updates, announcements, and support-related communications.
      </p>
      <p>
        <strong>2.3. Research and Analytics:</strong> We may analyze user interactions with the Bot to gain insights and improve its features.
      </p>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell, rent, or trade your personal information with third parties. However, we may share your information in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in delivering and maintaining the Bot.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to valid legal requests.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no security measures are completely foolproof, and we cannot guarantee the security of your data.
      </p>

      <h2>5. Children's Privacy</h2>
      <p>
        The Bot is not intended for children under the age of 13. We do not knowingly collect or store personal information from children under 13. If you believe we have inadvertently collected information from a child under 13, please contact us, and we will promptly delete it.
      </p>

      <h2>6. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on this page.
      </p>

      <h2>7. Contact Information</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at harambot@harambot.io.
      </p>

      <p>
        By using the Bot, you acknowledge that you have read, understood, and agreed to this Privacy Policy and the Terms of Service.
      </p>
    </div>
    </div>
  );
}

export default Policy;
