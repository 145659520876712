import React from 'react';
import {Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Tos from './pages/tos';
import Policy from './pages/policy';

function AppRoutes() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/tos" element={<Tos />} />
      </Routes>
  );
}

export default AppRoutes;

