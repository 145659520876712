import React from 'react';
import { NavLink } from 'react-router-dom';

function Nav() {
  return (
    <nav>
      <ul>
        <li>
        <a href="https://discord.com/oauth2/authorize?client_id=603009358003175424&permissions=277562378304&scope=bot">
            <img src='https://img.shields.io/badge/Add_To_Discord-622be2.svg?style=for-the-badge&logo=discord&logoColor=white' alt='Add harambot'/>
        </a>
        </li>
        <li>
        <a href="https://discord.gg/e22UCJNyPP">
            <img src='https://img.shields.io/badge/Join_Our_Discord-622be2.svg?style=for-the-badge&logo=discord&logoColor=white' alt='Join Harambot discord server'/>
        </a>
        </li>
        <li>
        <a href="https://twitter.com/harambot_io">
            <img src='https://img.shields.io/badge/follow-0e0c0f?style=for-the-badge&logo=x' alt='Follow harambot'/>
        </a>
        </li>
        <li>
          <a href="https://github.com/DMcP89/harambot">
            <img src="https://img.shields.io/badge/Open_Source-black?style=for-the-badge&logo=github&logoColor=white" alt='GitHub Repo'/>
          </a>
        </li>
        <li>
          <NavLink to="/policy">
            <img src='https://img.shields.io/badge/Private_Policy-706b75?style=for-the-badge' alt='Private Policy'/>
          </NavLink>
        </li>
        <li>
          <NavLink to="/tos">
          <img src='https://img.shields.io/badge/Terms_Of_Service-706b75?style=for-the-badge' alt='Private Policy'/>
            </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
