import React from "react";
import harambot_text from "../assets/images/harambot_text.png";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <>
      <Link to="/">
        <img className="logo-img" src={harambot_text} alt="Harambot" style={{ width: "75%", height: "auto" }} />
      </Link>
      <br />
      <i> An Interactive Yahoo Fantasy Bot for Discord</i>
    </>
  );
}

export default Logo;